<div class="d-flex align-items-center justify-content-between mb-4">
    <span class="fw-bold text-dark">{{"Side nav color" | translate}}:</span>
    <div class="btn-group btn-group-sm" 
        btnRadioGroup 
        [(ngModel)]="config.navMenuColor" 
        ngDefaultControl
        (ngModelChange)="configChange()"
    >
        <label class="btn btn-outline-primary" btnRadio="light">{{"Light" | translate}}</label>
        <label class="btn btn-outline-primary" btnRadio="dark">{{"Dark" | translate}}</label>
    </div>
</div>
<div class="d-flex align-items-center justify-content-between mb-4">
    <span class="fw-bold text-dark">{{"Layout Type" |  translate}}:</span>
    <div class="btn-group btn-group-sm" 
        btnRadioGroup 
        [(ngModel)]="config.layoutType" 
        ngDefaultControl
        (ngModelChange)="configChange()"
    >
        <label class="btn btn-outline-primary" btnRadio="vertical">{{"Vertical" | translate}}</label>
        <label class="btn btn-outline-primary" btnRadio="horizontal">{{"Horizontal" | translate}}</label>
    </div>
</div>
<div class="d-flex align-items-center justify-content-between mb-4">
    <div class="fw-bold text-dark">{{"Side nav collapse" | translate}}:</div>
    <switch name="basicSwitch" [(ngModel)]="config.sideNavCollapse" (ngModelChange)="configChange()"></switch>
</div>
<div class="mb-4">
    <div class="fw-bold text-dark">{{"Header nav color" |  translate}}:</div>
    <div class="mt-3 d-flex">
        <div 
            class="color-selector ms-2" 
            [ngClass]="{'text-white': color !== '#ffffff'}" 
            [ngStyle]="{backgroundColor: color}" 
            *ngFor="let color of headerNavColors"
            (click)="onColorChange(color)"
        >
            <i *ngIf="color === config.headerNavColor" class="feather icon-check"></i>
        </div>
    </div>
</div>